body {
  background-color: $light-grey;
  font-family: 'Avenir Light', 'Avenir Book', 'Avenir', 'Helvetica', Arial;
  font-weight:normal;
  color: $dark-grey;
  -webkit-font-smoothing: antialiased;
}

h1, h2, .separator {
  font-size: 24px;
  font-weight: normal;
}
h1 {
  margin-bottom: 6px;
}
h2, .separator {

  a {
  color: $grey;
  }

  a:hover {
  color: $dark-grey;
  }

  color: $grey;
}

.separator {
  display: block;
  margin-bottom: 8px;
}

p {
  font-size: 24px;
  margin-bottom: 0;
}

a {
  color: $dark-grey;
  &:hover {
    text-decoration: none;
    color: $grey;
  }
}


.container-fluid {
  @include media-breakpoint-up(sm) {
    padding: 80px;
    @media screen and (min-height: 365px) {
      .contact {
        position: fixed;
        bottom: 69px;
      }
    }
    @media screen and (max-height: 365px){
      position: relative;
    }
  }
  @include media-breakpoint-only(xs){
    padding: 30px;

    .contact {
    margin-top:60px;
    }

  }
}

.fadein {
  opacity: 0;
}

.enquire {
  @include media-breakpoint-only(xs){
    margin-bottom: 13px;
    display: inline-block;
  }
}
