// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$light-grey: #f6f6f6;
$grey: #969696;
$dark-grey: #323232;

// Colors
$brand-primary:         $grey;

